import React from 'react';
import { NowPlayingContainer } from './styles';
import banner from '../../assets/banner.webp';
import { LibraryDataRes } from '../../types/res';
import { Grid2 as Grid } from '@mui/material';
import unfinished from '../../assets/icons/unfinished.gif';
import beaten from '../../assets/icons/beaten.gif';
import completed from '../../assets/icons/completed.gif';

export interface NowPlayingProps {
  data: LibraryDataRes[];
}

export const NowPlaying: React.FC<NowPlayingProps> = ({ data }) => {
  const renderIconPerStatus = (status: string): React.ReactNode => {
    switch (status) {
      case 'Unfinished':
        return <img src={unfinished} />;
      case 'Beaten':
        return <img src={beaten} />;
      case 'Completed':
        return <img src={completed} />;
      default:
        return '';
    }
  };

  const parseLabelPerPlatform = (console: string): string => {
    const labelMap: Record<string, string> = {
      'Nintendo Switch': 'Switch',
      'PlayStation 4': 'PS4',
      'PlayStation 3': 'PS3'
    };
    return labelMap[console] ?? console;
  };

  return (
    <NowPlayingContainer>
      <img src={banner} />
      <h1>Now Playing</h1>

      <Grid container>
        {data.map((game, i) => (
          <>
            <Grid size={2} key={i}>
              {renderIconPerStatus(game.status)} {parseLabelPerPlatform(game.platform)}
            </Grid>
            <Grid size={5}>{game.title}</Grid>
            <Grid size={4}>{game.Notes}</Grid>
            <Grid size={1}>boh</Grid>
          </>
        ))}
      </Grid>
    </NowPlayingContainer>
  );
};
