import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { LinearProgress, linearProgressClasses } from '@mui/material';

export const MainContainer = styled('div')`
  grid-column: 2;
  color: white;
  font: 10pt arial, sans-serif;
  text-align: left;
`;

export const UselessTopBar = styled('div')`
  height: 40px;
  width: 800px;
  margin-bottom: 15px;
  border-radius: 0 0 10px 10px;
  background-color: #333;
  background-image: -moz-linear-gradient(top, #555, #333);
  background-image: -o-linear-gradient(top, #555, #333);
  box-shadow: 0 2px 0 #000;
`;

const RoundedCardStyle = css`
  background-image: -moz-linear-gradient(top, #334259, #1d2633);
  -moz-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.7);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.7);
  text-align: center;
  margin: 0 0 15px;
  border-radius: 10px;
  position: relative;
`;

export const NowPlayingContainer = styled('div')`
  ${RoundedCardStyle}
  padding: 20px 0;
  background-color: #bdd6a5;
  color: #067057;
`;

export const RedCardContainer = styled('div')`
  ${RoundedCardStyle}
  background-image: none;
  background-color: #ee3b24;
  color: #ffffff;
  padding: 10px 15px;
`;

export const QuoteBox = styled('div')`
  ${RoundedCardStyle}
  font-weight: bold;
  background-color: #bdd6a5;
  padding: 5px;
  margin: 0 0 15px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0.1)),
    to(rgba(0, 0, 0, 0.1))
  );
  color: #067057;
`;

export const Footer = styled('div')`
  position: relative;
  bottom: 0;
  font-size: 0.9em;
  height: 25px;
  color: white;
  padding: 10px;
  border: 1px solid #292929;
  border-radius: 16px 16px 0 0;
  background-color: #333;
  background-image: -moz-linear-gradient(top, #555, #333);
  background-image: -o-linear-gradient(top, #555, #333);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#555), to(#333));
  box-shadow: 0 2px 0 #000;
`;

export const CuteH1 = styled('h1')`
  position: relative;
  margin: 0;
  text-align: left;
  font-weight: normal;
  background-image: -moz-linear-gradient(top, #334259, #1d2633);
  background-image: -o-linear-gradient(top, #334259, #1d2633);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#334259), to(#1d2633));
  border-radius: 16px;
  padding: 5px 5px 5px 50px;
  padding-left: 55px;
  font-size: 18px;
  background-color: #bdd6a5;
  color: #067057;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0.1)),
    to(rgba(0, 0, 0, 0.1))
  );
`;

export const PopoutIcon = styled('img')`
  position: absolute;
  top: -17px;
  left: 0px;
  -moz-transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
`;

export const BabySpanInfo = styled('span')`
  background-color: #bdd6a5;
  color: #067057;
  background-image: none;
  font-weight: bold;
  padding: 0px 3px;
  font-size: 10px;
  position: relative;
  top: -3px;
  border-radius: 3px;
  -moz-box-shadow: 0 0 1px #000;
  -webkit-box-shadow: 0 0 1px #000;
  box-shadow: 0 0 1px #000;
`;

export const UnfinishedProgressBar = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    height: '14px',
    width: '350px',
    border: '1px solid #333',
    margin: '0 3px',
    borderRadius: '4px',
    background: 'rgba(255, 255, 255, 0.5)',
    backgroundImage: 'linear-gradient(to top, #660000, #330000)',
    backgroundColor: '#430000'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#990000',
    backgroundImage: 'linear-gradient(to bottom, #C77, #990000)'
  }
}));

export const BeatenProgressBar = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    height: '14px',
    width: '350px',
    border: '1px solid #333',
    margin: '0 3px',
    borderRadius: '4px',
    background: 'rgba(255, 255, 255, 0.5)',
    backgroundColor: '#414248',
    backgroundImage: 'linear-gradient(to top, #56575e, #37383d)'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#BDBDBD',
    backgroundImage: 'linear-gradient(to bottom, #EEE, #9B9B9B)'
  }
}));

export const CompletedProgressBar = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    height: '14px',
    width: '350px',
    border: '1px solid #333',
    margin: '0 3px',
    borderRadius: '4px',
    background: 'rgba(255, 255, 255, 0.5)',
    backgroundColor: '#483b22',
    backgroundImage: 'linear-gradient(to bottom, #604f2e, #3c321d)'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#FFCC66',
    backgroundImage: 'linear-gradient(to bottom, #FDB, #CCAA44)'
  }
}));

export const LibraryGreyCTA = styled('div')`
  color: white;
  padding: 0px;
  display: block;
  border: 1px solid black;
  text-align: center;
  font-weight: bold;
  width: 100%;
  margin: 2px 10px 2px 0;
  background-color: #777;
  background-image: -moz-linear-gradient(top, #999, #666);
  background-image: -o-linear-gradient(top, #999, #666);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#999), to(#666));
  text-shadow: 0 0 3px black;
`;
