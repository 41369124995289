import React from 'react';
import { LibraryDataRes } from '../../types/res';
import _ from 'lodash';
import { Grid2 as Grid } from '@mui/material';
import { LibraryGreyCTA } from './styles';
import unfinished from '../../assets/icons/unfinished.gif';
import beaten from '../../assets/icons/beaten.gif';
import completed from '../../assets/icons/completed.gif';

export interface LibraryProps {
  data: LibraryDataRes[];
}
export const Library: React.FC<LibraryProps> = ({ data }) => {
  const consoleList = data.map((v) => v.platform);
  const uniqueConsoleList = _.uniq(consoleList);
  return (
    <Grid container>
      {uniqueConsoleList.map((console, i) => {
        const unfinishedGamesPerConsole = data
          .filter((g) => g.platform === console)
          .filter((s) => s.status === 'Unfinished').length;
        const beatenGamesPerConsole = data
          .filter((g) => g.platform === console)
          .filter((s) => s.status === 'Beaten').length;
        const completedGamesPerConsole = data
          .filter((g) => g.platform === console)
          .filter((s) => s.status === 'Completed').length;

        return (
          <>
            <Grid key={i} size={4}>
              <LibraryGreyCTA>
                {console === 'Super Nintendo Entertainment System' ? 'SNES' : console}
              </LibraryGreyCTA>
            </Grid>
            <Grid size={2}>
              {unfinishedGamesPerConsole}
              <img src={unfinished} />
            </Grid>
            <Grid size={2}>
              {beatenGamesPerConsole}
              <img src={beaten} />
            </Grid>
            <Grid size={2}>
              {completedGamesPerConsole}
              <img src={completed} />
            </Grid>
            <Grid size={2}>Total</Grid>
          </>
        );
      })}
    </Grid>
  );
};
