import React, { useEffect, useState } from 'react';
import {
  Footer,
  MainContainer,
  QuoteBox,
  RedCardContainer,
  UselessTopBar
} from '../components/BackloggeryApp/styles';
import { NowPlaying } from '../components/BackloggeryApp/NowPlaying';
import { Grid2 as Grid } from '@mui/material';
import './backloggery.scss';
import { CuteTitleWithPopout } from '../components/BackloggeryApp/CuteTitleWithPopout';
import axios from 'axios';
import { LibraryDataRes } from '../types/res';
import { ProgressBarWidget } from '../components/BackloggeryApp/ProgressBarWidget';
import { Library } from '../components/BackloggeryApp/Library';

export const BackloggeryApp: React.FC = () => {
  const [libraryData, setLibraryData] = useState<LibraryDataRes[]>([]);
  if (!libraryData) return null;
  const fetchLibrary = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/library`).then((res) => {
        return setLibraryData(res.data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLibrary();
  }, []);

  const nowPlayingData = libraryData?.filter((element) => element.priority === 'Now Playing');
  return (
    <div className="backloggery-wrap">
      <MainContainer>
        <UselessTopBar />
        {nowPlayingData && <NowPlaying data={nowPlayingData} />}
        <Grid container spacing={2}>
          <Grid size={7}>
            <RedCardContainer>
              <CuteTitleWithPopout icon="unfinished">berta&apos;s Status</CuteTitleWithPopout>
              <ProgressBarWidget data={libraryData} />
              <Library data={libraryData} />
            </RedCardContainer>
            <RedCardContainer>
              <CuteTitleWithPopout icon="comments">Dialogue box</CuteTitleWithPopout>
            </RedCardContainer>
          </Grid>
          <Grid size={5}>
            <RedCardContainer>
              <CuteTitleWithPopout>berta</CuteTitleWithPopout>
            </RedCardContainer>
            <RedCardContainer>
              <CuteTitleWithPopout icon="note">hello there.</CuteTitleWithPopout>
            </RedCardContainer>
            <RedCardContainer>
              <CuteTitleWithPopout icon="mcard">Memory Card</CuteTitleWithPopout>
            </RedCardContainer>
            <RedCardContainer>
              <CuteTitleWithPopout icon="multitap">Multitap</CuteTitleWithPopout>
            </RedCardContainer>
          </Grid>
        </Grid>
        <QuoteBox>men cry not for themselves but for their comrades</QuoteBox>
        <Footer>tante care cose</Footer>
      </MainContainer>
    </div>
  );
};
